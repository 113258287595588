import jwt from "jsonwebtoken";
import UnauthorizedError from "@/components/errors/UnauthorizedError";

export const generateToken = ({ firstName, lastName }) => {
  const token = jwt.sign({ firstName, lastName }, process.env.KS_SECRET);

  return token;
};

export const decodeToken = (token) => {
  const err = (err, decoded) => {
    if (err) throw new UnauthorizedError();
  };
  const decodedToken = jwt.verify(token, process.env.KS_SECRET, err);

  return decodedToken;
};

export const setToken = (token) => {
  localStorage.setItem("token", token);
};

export const getToken = () => {
  const user = localStorage.getItem("token");
  if (!user) return null;

  return user;
};

export const clearToken = () => {
  localStorage.clear();
};

export const getAuthToken = () => {
  const token = getToken();

  if (token) return `Bearer ${token}`;

  return null;
};
