export default class CustomClientError extends Error {
  constructor(...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomClientError);
    }

    const { messages, status } = params[0];

    this.name = "CustomClientError";
    this.messages = messages;
    this.status = status;
  }
}
