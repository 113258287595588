import { useState, useCallback, useRef, useReducer } from 'react';
import { useLoginMode, LOGIN_MODE_SIGNUP } from '@/components/contexts/LoginModeContext';
import { useFadeEffect, slsFetch } from '@/components/Util';
import { useRouter } from 'next/router';
import styles from '@/styles/FrontPage.module.scss';
import cx from 'classnames';
import Link from 'next/link';
import Image from 'next/image';
import AppButtonSpinner from '@/components/klaudsolcms/AppButtonSpinner';
import { setToken } from '@/lib/JWT'

const LoginForm = ({ className, ...props }) => {
  const router = useRouter();
  const [, setLoginMode] = useLoginMode();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const initialState = {
    submitted: false,
    isError: false,
    isLoading: false,
    isLoginSuccessful: false
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'INIT':
        return {
          ...state,
          isLoading: true,
          submitted: false
        };
      case 'SUCCESS':
        return {
          ...state,
          isLoginSuccessful: true,
          isError: false,
          submitted: true
        }
      case 'ERROR':
        return {
          ...state,
          isLoginSuccessful: false,
          isError: true,
          submitted: true
        }
      case 'CLEANUP':
        return {
          ...state,
          isLoading: false
        }
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const onSubmit = useCallback((evt) => {
    evt.preventDefault();
    (async () => {
      try {
        dispatch({ type: 'INIT' });
        const response = await slsFetch('/api/login', {
          method: 'POST',
          headers: {
            'Content-type': 'application/json'
          },
          body: JSON.stringify({ email, password })
        });
        const { token } = await response.json();

        setToken(token);

        dispatch({ type: 'SUCCESS' });
        router.push(`/admin`);

      } catch (ex) {
        console.error(ex);
        dispatch({ type: 'ERROR' });
      } finally {
        dispatch({ type: 'CLEANUP' });
      }
      //}
    })();
  }, [email, password, router]);

  const errorBox = useRef();
  const successBox = useRef();

  useFadeEffect(errorBox, [state.submitted, state.isError]);
  useFadeEffect(successBox, [state.submitted, state.isLoginSuccessful]);

  return (
    <div className={cx(styles["bg_container"])}>

      <div className={cx(styles["hapinoy_logo_container"])}>
        <img src="/hapinoy/HapinoyLogo.png" className={cx(styles["hapinoy_logo"])} />
      </div>

      <div className={cx(styles['container_login_form'],)}>
        <div className={cx(styles['form_header'])}>
          <div className={cx(styles["text_login"])}> Login  </div>
          <div className={cx(styles['line'])}></div>
          <div className={cx(styles['text_description'])}>Welcome to Hapinoy Prize Tracker!</div>
        </div>

        <div className={cx(styles["form_login"])}  {...props}>
          <div ref={errorBox} className="alert alert-danger useFadeEffect px-3 pt-3 pb-2 mb-0 mt-3 error"> <p>Incorrect username and/or password.</p> </div>
          <div ref={successBox} className="alert alert-success useFadeEffect px-3 pt-3 pb-2 mb-0 mt-3 success"> <p>Welcome back! Logging you in...</p> </div>
          <label className={cx(styles["text_email"], 'mb-2 mt-3')}>Username</label>
          <input type='email' className={cx(styles['login_input'], 'input_login')} autoComplete="email" onChange={e => setEmail(e.target.value)} />

          <label className={cx(styles["text_password"], 'mb-2 mt-4')}>Password</label>
          <input type='password' className={cx(styles['login_input'], 'input_login')} autoComplete="email" onChange={e => setPassword(e.target.value)} />

        </div>

        <Link href='/admin/' passHref>
          <button className={cx(styles['login_button'], 'btn_login')} onClick={onSubmit}>
            {state.isLoading && <AppButtonSpinner />} LOG IN
          </button>
        </Link>

      </div>

      <img src="/hapinoy/Background.png" className={cx(styles["background_image"])} />
    </div>

  );

};

export default LoginForm;
